/*
    wLang v1.0 / 20.11.2015
    Studio WEZOM / Oleg Dutchenko
    Wezom wTPL v4.0.0
*/

window.wLang = (function(window, document, undefined) {
	// массив имеющихся локализации
		var names = ['ru', 'ua', 'pl', 'en'];
	// флаг отсутсутвующей локализации
		var miss = true;
	// язык по умолчанию
		var def = 'ru';
	// навигатор объекта Window
		var navi = window.navigator;
	// определяем язык документа
		var lang = document.documentElement.getAttribute('lang') ||
			navi.language ||
			navi.browserLanguage ||
			w.userLanguage || def;

	// от кода языка отсекаем код региона(если присутствует) и переводим в нижний регистр
		lang = lang.toLowerCase().substr(0, 2);

	// сверяем полученый результат с имеющимся массивом
		for (var i = 0; i < names.length; i++) {
			if (lang === names[i]) {
				miss = false;
				break;
			}
		}

	// если получаем отсутствующий вариант определяем язык по умолчанию
		if (miss) {
			lang = def;
		}

	// возвращает язык в качестве нового свойтства объекту Window
	// далее свойтво можно использавать как значение глобальной переменной wLang
	// console.log(wLang);
		return lang;

})(this, this.document);

/*
    wLocalize v1.0 / 20.11.2015
    Studio WEZOM / Oleg Dutchenko
*/

window.wLocalize = (function(window, document, undefined) {
	// объект содержащий переводы
		var localize = {};

    // переводы для плагина jquery.magnific-popup
        localize.Magnific = {
            ru: {
                tClose: 'Закрыть (ESC)',
                tLoading: 'Загрузка контента ...',
                tNotFound: 'Контент не найден',
                tError: 'Невозможно загрузить <a href="%url%" target="_blank">Контент</a>.',
                tErrorImage: 'Невозможно загрузить <a href="%url%" target="_blank">Изображение #%curr%</a>.',
                tPrev: 'Предыдущая (клавиша Left)',
                tNext: 'Следующая (клавиша Right)',
                tCounter: '%curr% из %total%'
            },
            ua: {
                tClose: 'Закрити (ESC)',
                tLoading: 'Завантаження контенту ...',
                tNotFound: 'Контент не знайдено',
                tError: 'Неможливо завантажити <a href="%url%" target="_blank">контент</a>.',
                tErrorImage: 'Неможливо завантажити <a href="%url%" target="_blank">Зображенння #%curr%</a>.',
                tPrev: 'Попередня (клавіша Left)',
                tNext: 'Наступна (клавіша Right)',
                tCounter: '%curr% з %total%'
            },
            pl: {
                tClose: 'Zamknąć (ESC)',
                tLoading: 'Zawartość do treści ...',
                tNotFound: 'Nie znaleziono treści',
                tError: 'Niemożliwe do ściągnięcia <a href="%url%" target="_blank">treści</a>.',
                tErrorImage: 'Niemożliwe do ściągnięcia <a href="%url%" target="_blank">Obrazów #%curr%</a>.',
                tPrev: 'Poprzednia (klucz Left)',
                tNext: 'Następna (klucz Right)',
                tCounter: '%curr% z %total%'
            },
            en: {
                tClose: 'Close (ESC)',
                tLoading: 'Loading ...',
                tNotFound: 'Content not found',
                tError: '<a href="%url%" target="_blank">The content</a> could not be loaded.',
                tErrorImage: '<a href="%url%" target="_blank">The image #%curr%</a> could not be loaded.',
                tPrev: 'Previous (Left arrow key)',
                tNext: 'Next (Right arrow key)',
                tCounter: '%curr% of %total%'
            }
        };

    // переводы для плагина jquery-validation
        localize.Validate = {
            ru: {
                required: "Это поле необходимо заполнить!",
                required_checker: "Этот параметр - обязателен!",
                required_select: "Этот параметр - обязателен!",
                required: "Это поле необходимо заполнить!",
                password: "Укажите корректный пароль!",
                remote: "Пожалуйста, введите правильное значение!",
                email: "Пожалуйста, введите корректный адрес электронной почты!",
                url: "Пожалуйста, введите корректный URL!",
                date: "Пожалуйста, введите корректную дату!",
                dateISO: "Пожалуйста, введите корректную дату в формате ISO!",
                number: "Пожалуйста, введите число!",
                digits: "Пожалуйста, вводите только цифры!",
                creditcard: "Пожалуйста, введите правильный номер кредитной карты!",
                equalTo: "Пожалуйста, введите такое же значение ещё раз!",
                maxlength: "Пожалуйста, введите не более {0} символов!",
                maxlength_checker: "Пожалуйста, выберите не более {0} параметров!",
                maxlength_select: "Пожалуйста, выберите не более {0} пунктов!",
                minlength: "Пожалуйста, введите не менее {0} символов!",
                minlength_checker: "Пожалуйста, выберите не менее {0} параметров!",
                minlength_select: "Пожалуйста, выберите не менее {0} пунктов!",
                rangelength: "Пожалуйста, введите значение длиной от {0} до {1} символов!",
                rangelength_checker: "Пожалуйста, выберите от {0} до {1} параметров!",
                rangelength_select: "Пожалуйста, выберите от {0} до {1} пунктов!",
                range: "Пожалуйста, укажите значение от {0} до {1}!",
                filetype: "Допустимые расширения файлов: {0}!",
                filesize: "Максимальный объем {0}KB!",
                filesizeEach: "Максимальный объем каждого файла {0}KB!",
                max: "Пожалуйста, укажите значение, меньше или равное {0}!",
                min: "Пожалуйста, укажите значение, больше или равное {0}!",
                pattern: "Укажите значение соответствующее маске {0}!",
                // add
                word: "Введите корректное словесное значение!",
                login: "Введите корректный логин!",
                phoneUA: "Некорректный формат украинского номера"
            },
            ua: {
                required: "Це поле необхідно заповнити!",
                required_checker: "Цей параметр - обов'язковий!",
                required_select: "Цей параметр - обов'язковий!",
                password: "Вкажіть корректний пароль!",
                remote: "Будь ласка, введіть правильне значення!",
                email: "Будь ласка, введіть коректну адресу електронної пошти!",
                url: "Будь ласка, введіть коректний URL!",
                date: "Будь ласка, введіть коректну дату!",
                dateISO: "Будь ласка, введіть коректну дату у форматі ISO!",
                number: "Будь ласка, введіть число!",
                digits: "Будь ласка, вводите тільки цифри!",
                creditcard: "Будь ласка, введіть правильний номер кредитної картки!",
                equalTo: "Будь ласка, введіть таке ж значення ще раз!",
                maxlength: "Будь ласка, введіть не більш {0} символів!",
                maxlength_checker: "Будь ласка, виберіть не більш {0} параметрів!",
                maxlength_select: "Будь ласка, виберіть не більш {0} пунктів!",
                minlength: "Будь ласка, введіть не менш {0} символів!",
                minlength_checker: "Будь ласка, виберіть не менше {0} параметрів!",
                minlength_select: "Будь ласка, виберіть не менш {0} пунктів!",
                rangelength: "Будь ласка , введіть значення довжиною від {0} до {1} символів!",
                rangelength_checker: "Будь ласка, виберіть від {0} до {1} параметрів!",
                rangelength_select: "Будь ласка, виберіть від {0} до {1} пунктів!",
                range: "Будь ласка, вкажіть значення від {0} до {1}!",
                filetype: "Допустимі розширення файлів: {0}!",
                filesize: "Максимальний обсяг {0}KB!",
                filesizeEach: "Максимальный размер каждого файла {0}KB!",
                max: "Будь ласка, вкажіть значення, менше або рівне {0}!",
                min: "Будь ласка, вкажіть значення, більше або рівне {0}!.",
                pattern: "Вкажіть значення відповідне {0}!",
                // add
                word: "Введіть коректне ім'я!",
                login: "Введіть коректний логін!",
                phoneUA: "Некоректний формат українського номера"
            },
            pl: {
                required: "To pole należy wypełnić!",
                required_checker: "Parametr ten jest wymagany!",
                required_select: "Parametr ten jest wymagany!",
                password: "Określ paroll!",
                remote: "Proszę, wprowadźcie prawidłowe znaczenie!",
                email: "Proszę wpisać poprawny adres e-mail!",
                url: "Proszę podać poprawny adres URL!",
                date: "Wpisz poprawną datę!",
                dateISO: "Proszę podać poprawną datę w formacie ISO!",
                number: "Proszę wpisać numery!",
                digits: "Proszę wpisać tylko liczby!",
                creditcard: "Proszę podać poprawny numer karty kredytowej!",
                equalTo: "Proszę ponownie wprowadzić wartość!",
                maxlength: "Proszę wpisać nie więcej niż {0} znaków!",
                maxlength_checker: "Proszę wybrać nie więcej niż {0} parametrów!",
                maxlength_select: "Proszę wybrać nie więcej niż {0} pozycji!",
                minlength: "Proszę podać co najmniej {0} znaków!",
                minlength_checker: "Wybierz co najmniej {0} opcje!",
                minlength_select: "Wybierz co najmniej {0} przedmioty!",
                rangelength: "Prosimy podać wartość pomiędzy {0} {1} znaków!",
                rangelength_checker: "Proszę wybrać od {0} do {1} parametrów!",
                rangelength_select: "Proszę wybrać od {0} do {1} punktów!",
                range: "Prosimy podać wartość między {0} - {1}!",
                filetype: "Dopuszczalne rozszerzenia plików: {0}!",
                filesize: "Maksymalny rozmiar {0}KB!",
                filesizeEach: "Maksymalny rozmiar każdego plika {0}KB!",
                max: "Prosimy podać wartość mniejsza lub równa {0}!",
                min: "Prosimy podać wartość większą lub równą {0}!",
                pattern: "Określ wartość odpowiadającą maski {0}!",
                // add
                word: "Wprowadź poprawne znaczenie słów!",
                login: "Proszę podać poprawną nazwę użytkownika!",
                phoneUA: "Nieprawidłowy format numeru telefonu ukraiński"
            },
            en: {
                required: "This field is required!",
                required_checker: "This parameter is required!",
                required_select: "This parameter is required!",
                password: "Specify paroll!",
                remote: "Please fix this field!",
                email: "Please enter a valid email address!",
                url: "Please enter a valid URL!",
                date: "Please enter a valid date!",
                dateISO: "Please enter a valid date ISO!",
                number: "Please enter a valid number!",
                digits: "Please enter only digits!",
                creditcard: "Please enter a valid credit card number!",
                equalTo: "Please enter the same value again!",
                maxlength: "Please enter no more than {0} characters!",
                maxlength_checker: "Please select no more than {0} parameters!",
                maxlength_select: "Please select no more than {0} items!",
                minlength: "Please enter at least {0} characters!",
                minlength_checker: "Please select at least {0} options!",
                minlength_select: "Please select at least {0} items!",
                rangelength: "Please enter a value between {0} and {1} characters long!",
                rangelength_checker: "Please select from {0} to {1} options!",
                rangelength_select: "Please select from {0} to {1} items!",
                range: "Please enter a value between {0} and {1}!",
                filetype: "Allowed file extensions: {0}!",
                filesize: "Maximum size {0}KB!",
                filesizeEach: "Maximum amount of each file {0}KB!",
                max: "Please enter a value less than or equal to {0}!",
                min: "Please enter a value greater than or equal to {0}!",
                pattern: "Specify a value corresponding to the mask {0}!",
                // add
                word: "Please enter the correct word meanings!",
                login: "Please enter a valid username!",
                phoneUA: "Invalid phone number format Ukrainian"
            }
        };

	// возвращает объект с переводам в качестве нового свойтства объекту Window
	// далее объект можно использавать как значение глобальной переменной wLocalize
	// console.log(wLocalize);

        return localize;

	// пример использования
	// переведенный текст загрузки плагина MagnificPopup
	// console.log(wLocalize.Magnific[wLang].tLoading);

})(this, this.document);
